<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Nominación Regalías</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Nominación Regalías</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-2">
                    <label for="">
                      Buscar en la Tabla
                    </label>
                    <div>
                      <input
                        type="text"
                        class="form-control form-control-sm shadow-sm"
                        v-model="filter"
                      />
                    </div>
                  </div>
                  <div v-if="loading" class="col-md-1">
                    <div
                      class="spinner-border text-warning float-right mt-3"
                      role="status"
                    >
                      <span class="sr-only">Guardando...</span>
                    </div>
                  </div>
                  <div :class="loading ? 'col-md-7' : 'col-md-8'"></div>
                  <div class="col-md-2">
                    <div class="btn-group shadow float-right mt-3">
                      <!-- <button
                    type="button"
                    class="btn bg-gradient-success btn-md"
                    data-toggle="modal"
                    data-target="#modal_informe_excel"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.almacenBonos.exportExcelBonos'
                      )
                    "
                    @click="$refs.BonoExportExcel.limpiarModal()"
                  >
                    <i class="far fa-file-excel"></i>
                  </button> -->
                      <button
                        type="button"
                        class="btn bg-gradient-primary btn-md"
                        style="cursor: pointer"
                        @click="create()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.nominacionesRegalias.create'
                          )
                        "
                      >
                        <i class="fas fa-plus"></i>
                        <!-- <i class="fas fa-user-plus"></i> -->
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-info"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-danger"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="table table-bordered table-striped table-hover table-sm shadow-sm"
                      style="font-size: 0.8em"
                    >
                      <thead>
                        <tr>
                          <th class="text-center">
                            <label>#</label>
                            <input
                              type="number"
                              v-model="filtros.id"
                              label="id"
                              class="form-control form-control-sm shadow-sm"
                              @keyup.enter="getIndex()"
                            />
                          </th>
                          <th class="text-center">
                            <label>Nombre</label>
                            <input
                              type="text"
                              v-model="filtros.nombre"
                              label="id"
                              class="form-control form-control-sm shadow-sm"
                              @keyup.enter="getIndex()"
                            />
                          </th>
                          <th class="text-center">
                            <label>Fecha Inicial</label>
                            <input
                              type="date"
                              v-model="filtros.fecha_inicial"
                              label="id"
                              class="form-control form-control-sm p-0 shadow-sm"
                              @change="getIndex()"
                            />
                          </th>
                          <th class="text-center">
                            <label>Fecha Final</label>
                            <input
                              type="date"
                              v-model="filtros.fecha_final"
                              label="id"
                              class="form-control form-control-sm p-0 shadow-sm"
                              @change="getIndex()"
                            />
                          </th>
                          <th class="text-center">
                            Estado
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.estado"
                              @change="getIndex()"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </th>
                          <th class="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in filteredRows" :key="item.id">
                          <td
                            v-html="highlightMatches(item.id)"
                            class="text-center"
                          ></td>
                          <td v-html="highlightMatches(item.nombre)"></td>
                          <td
                            class="text-center"
                            v-html="highlightMatches(item.fecha_inicial)"
                          ></td>
                          <td
                            class="text-center"
                            v-html="highlightMatches(item.fecha_final)"
                          ></td>
                          <td class="text-center">
                            <span
                              class="badge  shadow"
                              :class="
                                item.estado == 1
                                  ? 'badge badge-pill badge-warning'
                                  : item.estado == 2
                                  ? 'badge badge-pill badge-success'
                                  : ''
                              "
                            >
                              {{ item.nEstado }}
                            </span>
                          </td>
                          <td
                            v-html="
                              highlightMatches(item.created_at.substring(0, 10))
                            "
                          ></td>
                          <td style="width: 50px">
                            <div class="btn-group float-right shadow-sm">
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-navy"
                                @click="edit(item)"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.nominacionesRegalias.edit'
                                  )
                                "
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-danger"
                                @click="eliminar(item.id)"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.nominacionesRegalias.delete'
                                  )
                                "
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div
                  class="float-left"
                  v-if="nominaciones.data && nominaciones.data.length > 0"
                >
                  <p>
                    Mostrando del <b>{{ nominaciones.from }}</b> al
                    <b>{{ nominaciones.to }}</b> de un total:
                    <b>{{ nominaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  class="float-right shadow"
                  :data="nominaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from "laravel-vue-pagination";
import { mapActions, mapState } from "vuex";
/* import vSelect from "vue-select"; */
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "NominacionRegaliasIndex",
  components: {
    Loading,
    pagination,
    /* vSelect, */
  },
  data() {
    return {
      page: 1,
      filter: "",
      cargando: false,
      bonos: {},
      filtros: {},
      nominaciones: {},
      listasForms: {
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },

  computed: {
    ...mapState("modOperacion", ["listas"]),
    ...mapState("modHidrocarburos", ["nomRegalias", "loading"]),
    filteredRows() {
      if (this.nominaciones.data) {
        return this.nominaciones.data.filter((row) => {
          const id = String(row.id).toLowerCase();
          const nombre = row.nombre.toLowerCase();
          const fecha_inicial = row.fecha_inicial;
          const fecha_final = row.fecha_final;
          const estado = row.estado == 1 ? "borrador" : "definitivo";
          const created_at = row.created_at;
          const searchTerm = this.filter.toLowerCase();

          return (
            id.includes(searchTerm) ||
            nombre.includes(searchTerm) ||
            fecha_inicial.includes(searchTerm) ||
            fecha_final.includes(searchTerm) ||
            estado.includes(searchTerm) ||
            created_at.includes(searchTerm)
          );
        });
      } else {
        return this.nominaciones;
      }
    },
  },

  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modHidrocarburos", ["actGetNomRegalias"]),
    highlightMatches(texto) {
      var text = String(texto);
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },

    getIndex(page = 1) {
      var data = [];
      this.filtros, (data[0] = page);
      data[1] = this.filtros;
      //alert(JSON.stringify(this.filtros));
      this.nominaciones = {};
      this.actGetNomRegalias(data).then(() => {
        this.nominaciones = this.nomRegalias;
      });
    },

    limpiar() {
      this.filtros.nombre = null;
      this.filtros.fecha_vigencia = null;
      this.nominacion_id = null;
      this.filtros.estado = null;
      this.filtros.id = null;
      this.getIndex();
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/NominacionesRegaliasForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(nominacion) {
      return this.$router.push({
        name: "/Hidrocarburos/NominacionesRegaliasForm",
        params: {
          accion: "Editar",
          nominacion_id: nominacion.id,
        },
      });
    },

    eliminar(id) {
      axios
        .delete("/api/hidrocarburos/nominacionesRegalias/" + id)
        .then((response) => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  async beforeMount() {
    await this.actGetListas(28).then(() => {
      this.listasForms.estados = this.listas;
    });
    (this.filtros = {
      nombre: null,
      fecha_inicial: null,
      fecha_final: null,
      estado: null,
    }),
      await this.actGetNomRegalias(this.filtros).then(() => {
        this.nominaciones = this.nomRegalias;
      });
  },

  mounted() {},
};
</script>
